import {
  Box,
  Button,
  Center,
  HStack,
  Stack,
  useBreakpointValue
} from '@chakra-ui/react'
import dynamic from 'next/dynamic'
import Link from 'next/link'
import {CSSProperties} from 'react'
import {FaExternalLinkAlt} from 'react-icons/fa'

import {navSize, space} from 'config/theme'

import LogoWithText from './logo-text'

const BurgerMenu = dynamic(() => import('./burger-menu'), {ssr: false})

const ExternalLink = () => (
  <Box opacity={0.5} mt='2px'>
    <FaExternalLinkAlt size='0.65rem' />
  </Box>
)

const blur = 'blur(5px)'
const navStyle: CSSProperties = {
  backdropFilter: blur,
  WebkitBackdropFilter: blur
}

const AllLinks = [
  {
    title: 'Features + Benefits',
    href: '/learn'
  },
  {
    title: 'Team',
    href: '/about'
  },
  {
    title: 'Client stories',
    href: '/clients'
  },
  {
    title: 'Documentation',
    href: 'https://docs.conveyal.com'
  },
  {
    title: 'Company Blog',
    href: 'https://blog.conveyal.com'
  }
]

// Spacer for the tops of layouts
export function NavSpacer() {
  return <Box height={navSize} />
}

export default function Nav() {
  const showBurgerMenu = useBreakpointValue({base: true, md: false})

  return (
    <Center
      backgroundColor='rgba(255, 255, 255, 0.80)'
      borderBottomWidth='1px'
      position='fixed'
      py={{base: 2, md: 3}}
      px={space.md}
      justify='space-between'
      style={navStyle}
      top={0}
      width='100%'
      zIndex={1000}
    >
      <HStack width={{base: '100%', md: 1200}} justify='space-between'>
        <Box>
          <LogoWithText />
        </Box>

        {showBurgerMenu ? (
          <BurgerMenu>
            <Stack spacing={space.sm}>
              <Link href='/contact' passHref>
                <Button colorScheme='blue' variant='outline'>
                  Contact
                </Button>
              </Link>
              <Link href='/contact/new' passHref>
                <Button colorScheme='blue'>Get started</Button>
              </Link>
              <Button
                as='a'
                colorScheme='blue'
                href='https://docs.conveyal.com'
                rel='noopener'
                rightIcon={<ExternalLink />}
                target='_blank'
                variant='outline'
              >
                Docs
              </Button>
              {AllLinks.map((item, key) => (
                <Link href={item.href} key={key} passHref>
                  <Button colorScheme='blue' variant='ghost'>
                    {item.title}
                  </Button>
                </Link>
              ))}
            </Stack>
          </BurgerMenu>
        ) : (
          <HStack spacing={space.xs}>
            <Link href='/learn' passHref>
              <Button
                colorScheme='blue'
                size='md'
                style={{fontVariant: 'small-caps'}}
                variant='ghost'
              >
                features + benefits
              </Button>
            </Link>

            <Link href='/about' passHref>
              <Button
                colorScheme='blue'
                size='md'
                style={{fontVariant: 'small-caps'}}
                variant='ghost'
              >
                team
              </Button>
            </Link>

            <Button
              as='a'
              colorScheme='blue'
              href='https://docs.conveyal.com'
              rel='noopener'
              rightIcon={<ExternalLink />}
              style={{fontVariant: 'small-caps'}}
              target='_blank'
              variant='ghost'
            >
              docs
            </Button>

            <Button
              as='a'
              colorScheme='blue'
              href='https://blog.conveyal.com'
              rel='noopener'
              rightIcon={<ExternalLink />}
              style={{fontVariant: 'small-caps'}}
              target='_blank'
              variant='ghost'
            >
              blog
            </Button>

            <Button
              as='a'
              colorScheme='blue'
              display={{base: 'none', md: 'inherit'}}
              href='https://analysis.conveyal.com'
              rel='noopener'
              rightIcon={<ExternalLink />}
              style={{fontVariant: 'small-caps'}}
              target='_blank'
              variant='ghost'
            >
              log in
            </Button>
            <Box>
              <Link href='/contact' passHref>
                <Button
                  colorScheme='blue'
                  style={{fontVariant: 'small-caps'}}
                  variant='outline'
                >
                  contact
                </Button>
              </Link>
            </Box>
          </HStack>
        )}
      </HStack>
    </Center>
  )
}
