import Head from 'next/head'

import Nav from 'components/nav'
import Footer from 'components/footer'

const defaultPageDescription =
  'The platform for detailed and rapid accessibility analysis of transport and land-use scenarios.'

export type MainLayoutProps = {
  children: JSX.Element | JSX.Element[]
  description?: string
  title: string
}

/**
 * Minimal layout that each other layout should be based on.
 */
export default function MainLayout({
  children,
  description,
  title
}: MainLayoutProps) {
  const finalTitle = `Conveyal - ${title}`
  const finalDescription = description ?? defaultPageDescription
  return (
    <>
      <Head>
        <title id='MainLayoutTitle'>{finalTitle}</title>
        <meta property='og:title' content={finalTitle} />
        <meta name='description' content={finalDescription} />
        <meta property='og:description' content={finalDescription} />
      </Head>
      <Nav />
      {children}
      <Footer />
    </>
  )
}
